import http from '@/_helpers/http';
import UserService from '@/_services/user.service';

export default class registerService extends UserService {
  /**
   * sprawdza czy kod z SMS jest poprawny
   * @param token - kod z SMS
   * @returns Promise
   */
  checkToken(token) {
    return http.axios.post(`${http.api.ACTION.REGISTER_TOKEN_CHECK}`, {
      token,
    });
  }

  /**
   * zwraca NR tel gdzie był wysłany SMS
   * @returns string
   */
  getSmsSended() {
    return JSON.parse(localStorage.register_step1).cell_phone;
  }

  stepOneSend(form) {
    return http.axios.post(`${http.api.ACTION.REGISTER_FIRST}`, form);
  }

  stepTwoSend(form) {
    return http.axios.post(`${http.api.ACTION.REGISTER_SECOND}`, form);
  }
}
