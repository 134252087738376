<template>
  <div>
    <div v-if="typeAlert === 'fifty-points'">
      <b-alert :show="true" v-if="!user.user_marketing ">
        Udziel jeszcze poniższej zgody, a otrzymasz na start <b>{{ $bonus_points }} punktów
        bonusowych!</b>
      </b-alert>
      <b-alert :show="true" variant="success" v-if="user.user_marketing ">
        Gratulacje! Otrzymasz na start <b>{{ $bonus_points }} punktów bonusowych!</b>
      </b-alert>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    typeAlert: String,
    user: Object,
  },
};
</script>
