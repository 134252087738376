<template>
  <b-container fluid>
    <b-container>
      <b-form>
        <div v-if="step === 1">
          <h1>Rejestracja w programie</h1>
          <div class="first-step-text">
          Dokonaj rejestracji w programie - tylko zarejestrowany Uczestnik może odbierać nagrody!
          </div>
          <b-form-group id="card-card_nr" label="Nr karty" label-for="card_nr">
            <b-input-group prepend="209">
              <b-form-input v-model="form.step1.card_nr"
                            @input="$v.form.step1.card_nr.$touch()"
                            @change="resetApiValidation('card_nr')"
                            :state="(!$v.form.step1.card_nr.$error && validation.card_nr == null) && null"
                            aria-describedby="card-feedback"></b-form-input>
              <b-form-invalid-feedback id="card-feedback">
                <div class="error" v-if="!$v.form.step1.card_nr.required">Pole wymagane</div>
                <div class="error" v-if="!$v.form.step1.card_nr.minLength">Niepoprawny numer karty</div>
                <div class="error" v-if="!$v.form.step1.card_nr.numeric">Pole musi być numeryczne</div>
                {{validation.card_nr}}
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>

          <b-form-group id="cell_phone-card_nr" label="Nr tel" label-for="cell_phone">
            <b-input-group prepend="+48">
              <b-form-input v-model="form.step1.cell_phone"
                            @input="$v.form.step1.cell_phone.$touch()"
                            @change="resetApiValidation('cell_phone')"
                            :state="(!$v.form.step1.cell_phone.$error && validation.cell_phone == null) && null"
                            aria-describedby="cell_phone-feedback"></b-form-input>
              <b-form-invalid-feedback id="cell_phone-feedback">
                <div class="error" v-if="!$v.form.step1.cell_phone.required">Pole wymagane</div>
                <div class="error" v-if="!$v.form.step1.cell_phone.minLength">Niepoprawny numer telefonu</div>
                <div class="error" v-if="!$v.form.step1.cell_phone.numeric">Pole musi być numeryczne
                </div>
                {{validation.cell_phone}}
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>


          <b-button variant="primary" @click="stepOne()" :disabled="loading" class="float-right">
            <b-spinner type="grow" label="Spinning" small v-if="loading"></b-spinner>
            Przejdź dalej
          </b-button>


        </div>
        <div v-if="step === 2">
          <h1 class="mb-4">Uzupełnij formularz rejestracyjny</h1>

          <b-form-group id="sms" label=" " label-for="sms">
            Podaj kod, który wysłaliśmy na numer <b>{{ getSmsSended }}</b>
            <b-form-input v-model="form.step2.token" class="mt-2"
                          @input="$v.form.step2.token.$touch()"
                          @change="resetApiValidation('token') && checkToken()"
                          :state="validation.token===true || ((!$v.form.step2.token.$error && validation.token == null) && null)"
                          aria-describedby="token-feedback"></b-form-input>
            <b-form-invalid-feedback id="token-feedback">
              <div class="error" v-if="!$v.form.step2.token.required">Pole wymagane</div>
              <div class="error" v-if="!$v.form.step2.token.numeric">Kod składa się z cyfr</div>
              {{validation.token}}
            </b-form-invalid-feedback>

          </b-form-group>
          <b-row>
          <b-form-group class="col-md-6"  id="birth-date" label="Data urodzenia" label-for="user_birth_date">
            <b-form-input :type="`date`" v-model="form.step2.user_birth_date"
                          @input="$v.form.step2.user_birth_date.$touch()"
                          @change="resetApiValidation('user_birth_date')"
                          :state="(!$v.form.step2.user_birth_date.$error && validation.user_birth_date == null) && null"
                          aria-describedby="birth-date-feedback"></b-form-input>
            <b-form-invalid-feedback id="birth-date-feedback">
              <div class="error" v-if="!$v.form.step2.user_birth_date.required">Pole wymagane</div>
              {{validation.user_birth_date}}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group  class="col-md-6" id="user_sex"  label="Płeć" label-for="user_sex">
            <b-form-select v-model="form.step2.user_sex"
                           @input="$v.form.step2.user_sex.$touch()"
                           @change="resetApiValidation('user_sex')"
                           :state="(!$v.form.step2.user_sex.$error && validation.user_sex == null) && null"
                           :options="user_sex"
                           aria-describedby="user_sex-feedback"></b-form-select>
            <b-form-invalid-feedback id="user_sex-feedback">
              <div class="error" v-if="!$v.form.step2.user_sex.beetween">Pole wymagane</div>
              {{validation.user_sex}}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group class="col-md-6" id="first-name" label="Imię" label-for="user_f_name">
            <b-form-input v-model="form.step2.user_f_name"
                          @input="$v.form.step2.user_f_name.$touch()"
                          @change="resetApiValidation('user_f_name')"
                          :state="(!$v.form.step2.user_f_name.$error && validation.user_f_name == null) && null"
                          aria-describedby="user_f_name-feedback"></b-form-input>
            <b-form-invalid-feedback id="user_f_name-feedback">
              <div class="error" v-if="!$v.form.step2.user_f_name.required">Pole wymagane</div>
              {{validation.user_f_name}}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group class="col-md-6" id="last-name" label="Nazwisko" label-for="lastname">
            <b-form-input v-model="form.step2.user_l_name"
                          @input="$v.form.step2.user_l_name.$touch()"
                          @change="resetApiValidation('user_l_name')"
                          :state="(!$v.form.step2.user_l_name.$error && validation.user_l_name == null) && null"
                          aria-describedby="user_f_name-feedback"></b-form-input>
            <b-form-invalid-feedback id="user_f_name-feedback">
              <div class="error" v-if="!$v.form.step2.user_l_name.required">Pole wymagane</div>
              {{validation.user_l_name}}
            </b-form-invalid-feedback>
          </b-form-group>
          </b-row>
          <b-row>
          <b-form-group class="col-md-4" id="user_street" label="Ulica" label-for="user_street">
            <b-form-input v-model="form.step2.user_street"
                          @change="resetApiValidation('user_street')"
                          aria-describedby="user_street-feedback"></b-form-input>
            <b-form-invalid-feedback id="user_street-feedback">
              {{validation.user_street}}
            </b-form-invalid-feedback>
          </b-form-group>
            <b-form-group class="col-md-4" id="user_house_nr" label="Numer domu" label-for="user_house_nr">
              <b-form-input v-model="form.step2.user_house_nr"
                            @input="$v.form.step2.user_house_nr.$touch()"
                            @change="resetApiValidation('user_house_nr')"
                            :state="(!$v.form.step2.user_house_nr.$error && validation.user_house_nr == null) && null"
                            aria-describedby="user_house_nr-feedback"></b-form-input>
              <b-form-invalid-feedback id="user_house_nr-feedback">
                <div class="error" v-if="!$v.form.step2.user_house_nr.required">Pole wymagane</div>
                {{validation.user_house_nr}}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group class="col-md-4" id="user_local_nr" label="Numer lokalu" label-for="user_local_nr">
              <b-form-input v-model="form.step2.user_local_nr"></b-form-input>
            </b-form-group>
          </b-row>
          <b-form-group id="user_city" label="Miejscowość" label-for="user_city">
            <b-form-input v-model="form.step2.user_city"
                          @input="$v.form.step2.user_city.$touch()"
                          @change="resetApiValidation('user_city')"
                          :state="(!$v.form.step2.user_city.$error && validation.user_city == null) && null"
                          aria-describedby="user_city-feedback"></b-form-input>
            <b-form-invalid-feedback id="user_city-feedback">
              <div class="error" v-if="!$v.form.step2.user_city.required">Pole wymagane</div>
              {{validation.user_city}}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group id="user_post_code" label="Kod pocztowy" label-for="user_post_code">
            <b-form-input v-model="form.step2.user_post_code"
                          @input="$v.form.step2.user_post_code.$touch()"
                          @change="resetApiValidation('user_city')"
                          :state="(!$v.form.step2.user_post_code.$error && validation.user_post_code == null) && null"
                          aria-describedby="postcode-feedback"></b-form-input>
            <b-form-invalid-feedback id="postcode-feedback">
              <div class="error" v-if="!$v.form.step2.user_post_code.required">Pole wymagane</div>
              <div class="error" v-if="!$v.form.step2.user_post_code.minLength">Błędna wartość - zbyt krótki  kod pocztowy</div>
              <div class="error" v-if="!$v.form.step2.user_post_code.maxLength">Błędna wartość - zbyt długi kod pocztowy</div>
              {{validation.user_post_code}}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group id="shop" label="Sklep" label-for="shop" v-if="!disabled_shop">

            <b-form-select v-model="form.step2.user_shop_id"
                           @input="$v.form.step2.user_shop_id.$touch()"
                           @change="resetApiValidation('user_shop_id')"
                           :state="(!$v.form.step2.user_shop_id.$error && validation.user_shop_id == null) && null"
                           :options="shop_list"
                           aria-describedby="shop-feedback"></b-form-select>
            <b-form-invalid-feedback id="shop-feedback">
              <div class="error" v-if="!$v.form.step2.user_shop_id.required">Pole wymagane</div>
              {{validation.user_shop_id}}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group id="user_email" label="Adres e-mail" label-for="user_email">
            <b-form-input v-model="form.step2.user_email"
                          @input="$v.form.step2.user_email.$touch()"
                          @change="resetApiValidation('user_email')"
                          :state="(!$v.form.step2.user_email.$error && validation.user_email == null) && null"
                          aria-describedby="user_email-feedback"></b-form-input>
            <b-form-invalid-feedback id="user_email-feedback">
              <div class="error" v-if="!$v.form.step2.user_email.user_email">To nie jest poprawny adres e-mail</div>
              {{validation.user_email}}
            </b-form-invalid-feedback>

          </b-form-group>
          <div class="bg-light border  border-primary rounded fifty-points">
            <alert :user="form.step2" :typeAlert="`fifty-points`"></alert>
          <b-form-checkbox switch
                           id="user_marketing"
                           v-model="form.step2.user_marketing"
                           v-bind:true-value=true
                           v-bind:false-value=false

          >
            <div v-html="rules.MARKETING"></div>
          </b-form-checkbox>
          <br/>
          </div>
          <b-form-checkbox switch
                           id="user_marketingext"
                           v-model="form.step2.user_marketing_ext"
                           v-bind:true-value=true
                           v-bind:false-value=false
          >
            <div v-html="rules.MARKETING_EXT"></div>
          </b-form-checkbox>
          <br/>
          <b-form-group label-for="user_giodo">
            <b-form-checkbox switch
                             v-model="form.step2.user_giodo"
                             @input="$v.form.step2.user_giodo.$touch()"
                             @change="resetApiValidation('user_giodo')"
                             :state="(!$v.form.step2.user_giodo.$error) && null"
                             value="1"
                             unchecked-value="0"
                             aria-describedby="user_giodo-feedback"
            >
               <div v-html="rules.TOS" style="display:inline"></div>
            </b-form-checkbox>
            <div class="error" v-if="$v.form.step2.user_giodo.$error"></div>
          </b-form-group>
          <b-form-invalid-feedback id="user_giodo-feedback">
            <div class="error" v-if="!$v.user_giodo.between">Regulamin wymagany</div>
          </b-form-invalid-feedback>
          <rodo></rodo>
          <b-button variant="danger" :disabled="loading" @click="$refs.confirmReset.showModal()">
            Rezygnuję
          </b-button>
          <b-button variant="success" @click="stepTwo()" :disabled="loading" class="float-right">
            <b-spinner type="grow" label="Spinning" small v-if="loading"></b-spinner>
            Podsumowanie
          </b-button>

        </div>

        <div v-if="step === 3">
          <h1>Podsumowanie</h1>
          <ul>
            <li>Data urodzenia: {{ form.step2.user_birth_date }}</li>
            <li>Imię: {{ form.step2.user_f_name }}</li>
            <li>Nazwisko: {{ form.step2.user_l_name }}</li>
            <li v-if="!disabled_shop">Sklep:
              <b-form-select readonly disabled v-model="form.step2.user_shop_id" :options="shop_list" size="sm" style="width:300px"></b-form-select>
            </li>
            <li>Ulica: {{ form.step2.user_street }}</li>
            <li>Numer domu: {{ form.step2.user_house_nr }}</li>
            <li v-if="form.step2.user_local_nr.length">Numer lokalu: {{ form.step2.user_local_nr }}</li>
            <li>Miejscowość: {{ form.step2.user_city }}</li>
            <li>Kod pocztowy: {{ form.step2.user_post_code }}</li>
            <li class="mb-3">Adres e-mail: {{ form.step2.user_email }}</li>
            <li class="mt-3">
              <b-form-checkbox :inline="true" disabled readonly
                               v-model="form.step2.user_marketing"
                               v-bind:true-value=true
                               v-bind:false-value=false />
              <div v-html="rules.MARKETING" style="display:inline"></div>
            </li>
            <li class="mt-3">
              <b-form-checkbox :inline="true" disabled readonly
                               v-model="form.step2.user_marketing_ext"
                               v-bind:true-value=true
                               v-bind:false-value=false />
              <div v-html="rules.MARKETING_EXT" style="display:inline"></div>
            </li>
            <li class="mt-3">
              <b-form-checkbox :inline="true" disabled readonly
                               id="user_giodos"
                               v-model="form.step2.user_giodo"
                               value="1"
                               unchecked-value="0" />
               <div v-html="rules.TOS" style="display:inline"></div>
            </li>
          </ul>
          <rodo></rodo>
          <b-button variant="warning" @click="prev()" :disabled="loading" class="float-left">
            <b-spinner type="grow" label="Spinning" small v-if="loading"></b-spinner>
            Popraw
          </b-button>

          <b-button variant="success" @click="save()" :disabled="loading" class="float-right">
            <b-spinner type="grow" label="Spinning" small v-if="loading"></b-spinner>
            Zarejestruj konto
          </b-button>
        </div>
        <div v-if="step === 4">
          <h1>Krok 4 </h1>
          Konto zostało poprawnie zarejestrowane. Możesz przejść na
          <b-link :to="`/user-data`">swoje konto</b-link>
        </div>
      </b-form>
    </b-container>
    <confirm-reset ref="confirmReset" :currentStep="step" v-on:resetForm="resetForm()"></confirm-reset>
  </b-container>
</template>

<script>

// komponenty
import {
  required, minLength, maxLength, between, numeric, email, minValue,
} from 'vuelidate/lib/validators';
import ConfirmReset from '@/components/Register/ConfirmReset.vue';
// import ConfirmNextPoints from '@/components/Register/ConfirmNextPoints.vue';
import Rodo from '@/components/Rodo.vue';
import Alert from '@/components/Register/Alert.vue';
// serwisy
import rules from '../_helpers/rules';
import RegisterService from '../_services/register.service';
import NotifyService from '../_services/notify';
import ShopService from '../_services/shop.service';

const registerService = new RegisterService();
const shopService = new ShopService();
const notify = new NotifyService();
export default {
  props: {},
  name: 'signup',
  components: {
    ConfirmReset, Rodo, Alert,
  },
  data() {
    return {
      rules: null,
      showConfirmReset: false,
      getSmsSended: null,
      user_sex: registerService.getSexOptions(),
      form: {
        step1: {
          card_nr: '',
          cell_phone: '',
        },
        step2: {
          token: null,
          user_birth_date: '',
          user_sex: 0,
          user_f_name: '',
          user_l_name: '',
          user_street: '',
          user_house_nr: '',
          user_local_nr: '',
          user_city: '',
          user_post_code: '',
          user_shop_id: null,
          user_email: '',
          user_marketing: false,
          user_profiling: true,
          user_marketing_ext: null,
          user_giodo: 0,
        },

      },
      validation: {
        card_nr: null,
        token: null,
        user_birth_date: null,
        user_sex: null,
        user_f_name: null,
        user_l_name: null,
      },
      loading: false,
      checking_sms: false,
      user_giodo: null,
      shop_list: [],
      step: 1,
      disabled_shop: true,
    };
  },
  async created() {
    this.rules = rules.RULES;
    const $step = localStorage.getItem('step');
    if ($step) {
      this.step = localStorage.getItem('step');
      this.step -= 1;
      this.step += 1;
    }
    if ($step > 1) {
      this.checkShopId();
    }
    this.shop_list = await shopService.getShopList();
    this.shop_list.unshift({ value: null, text: 'Wybierz sklep z listy' });
    Object.keys(this.form.step2).forEach((key) => {
      const registerStep2 = JSON.parse(localStorage.register_step2);
      if (typeof registerStep2[key] !== 'undefined') {
        this.$set(this.form.step2, key, registerStep2[key]);
      }
    });
  },
  watch: {
    step(newStep, oldStep) {
      localStorage.setItem('step', newStep);
      if (newStep === 2) {
        this.getSmsSended = registerService.getSmsSended();
        this.checkShopId();
      }
      if (newStep === 3 && oldStep === 2) {
        localStorage.register_step2 = JSON.stringify(this.form.step2);
        this.checkShopId();
      }
      if (newStep === 4) { // 4 step czyli rjestracja dokończona, czyscimy zmiene z localStorage i dajemy tam tylko token
        const token = localStorage.getItem('token');
        registerService.clearSession();
        localStorage.setItem('token', token);
      }
    },
  },
  methods: {
    resetForm() {
      localStorage.clear();
      this.step = 1;
    },
    checkToken() {
      if (!this.form.step2.token.length || this.$v.form.step2.token.$error) {
        return;
      }
      registerService.checkToken(this.form.step2.token)
        .then((response) => {
          const status = response.data.token_exists;
          if (status) {
            this.validation.token = true;
          } else {
            this.validation.token = 'Kod wygląda na niepoprawny';
          }
        });
    },
    checkShopId() {
      this.$store.state.loading = true;
      registerService.getUserData()
        .then((result) => {
          this.disabled_shop = false;
          if (result.shop_id > 0) {
            this.form.step2.user_shop_id = result.shop_id;
            this.disabled_shop = true;
          }
          this.$store.state.loading = false;
        });
    },
    resetApiValidation(key) {
      this.validation[key] = null;
      return true;
    },
    /**
       * krok pierwszy, sprawdzenie forms przed wyslaniem żądania
       */
    stepOne() {
      // jesli kuser_giodo wpisze karte z prefixem 209 mimo ze nie powinien i ma 13 znakow to ucinamy
      if (this.form.step1.card_nr != null && this.form.step1.card_nr && this.form.step1.card_nr.match(/^209/g)) {
        this.form.step1.card_nr = this.form.step1.card_nr.substring(3, 20);
      }
      // sprawdzamy czy nie ma zadnych wewnetrznych bledow przed wyslaniem zadania POST
      this.$v.form.step1.$touch();
      if (this.$v.form.step1.$anyError) {
        return;
      }
      this.stepOneSend();
    },
    prev() {
      this.step -= 1;
    },
    next() {
      this.step += 1;
    },
    /**
 * wysłanie formularza z danymi uczestnika
 *
 *
 */
    save() {
      this.stepTwoSend();
    },
    /**
       * Wysłanie formularza dla kroku 1
       */
    stepOneSend() {
      this.loading = true;
      registerService.stepOneSend({
        card_nr: `209${this.form.step1.card_nr}`,
        cell_phone: `48${this.form.step1.cell_phone}`,
      })
        .then((response) => {
          if (typeof response.data.errors !== 'undefined') {
            this.validationSet(response.data.errors);
            return;
          }
          localStorage.setItem('token', response.data.token);
          localStorage.register_step1 = JSON.stringify(this.form.step1);
          this.loading = false;
          this.next();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    stepTwo() {
      // sprawdzamy czy nie ma zadnych wewnetrznych bledow przed wyslaniem zadania POST
      this.$v.form.step2.$touch();
      if (this.$v.form.step2.$anyError) {
        return;
      }
      this.next();
    },
    stepTwoSend() {
      this.loading = true;
      registerService.stepTwoSend(this.form.step2)
        .then((response) => {
          if (response.data.success === true) {
            this.next();
            this.loading = false;
          }
        })
        .catch((error) => { // wysypka czyi blad 4xx
          if (typeof error.response === 'undefined') {
            notify.error('Błąd', 'Spróbuj jeszcze raz');
            this.prev();
            return;
          }
          if (error.response.status === 401) {
            this.step = 1;
            localStorage.removeItem('token');
            notify.error('Problem z autoryzacja', 'Coś się popsuło, musisz powtórzyć kroki');
            return;
          }
          if (typeof error.response.data.errors !== 'undefined') {
            this.validationSet(error.response.data.errors);
            notify.error('Woops!', 'Znaleźliśmy błędy w formularzu');
            this.step = 2;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validationSet(errors) {
      Object.keys(errors)
        .forEach((key) => {
          const [message] = errors[key];
          this.validation[key] = message;
        });
    },
  },
  /**
   * walidacje z Vuelidate
   */
  validations: {
    form: {
      step1: {
        card_nr: {
          numeric,
          required,
          minLength: minLength(9),
        },
        cell_phone: {
          numeric,
          required,
          minLength: minLength(9),
        },
      },
      step2: {
        token: {
          required,
          numeric,
        },
        user_birth_date: {
          required,
        },
        user_sex: {
          between: between(1, 2),
        },
        user_f_name: { required },
        user_l_name: { required },
        user_shop_id: { required },
        user_city: { required },
        user_house_nr: { required },
        user_post_code: { required, minLength: minLength(6), maxLength: maxLength(6) },
        user_email: {
          email,
        },
        user_giodo: {
          required,
          minValue: minValue(1),
        },
      },
    },
    user_giodo: { between: between(1, 2) },
  },
};
</script>
<style scoped>
  ul{
    list-style-type:  none;
  }
</style>
